import React, { Component } from "react";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import { injectStripe, CardElement } from "react-stripe-elements";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class UserPayment extends Helper {
  state = {
    displayAddCard: "none",
    data: {
      card_number: "",
      month: "",
      year: "",
      cvv: "",
    },
    loadingContent: null,
    buttonDisable: false,
    cardList: {},
    loadingCardList: true,
  };
  componentDidMount() {
    this.getCardList();
  }
  addCard = (ev) => {
    ev.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    if (this.props.stripe) {
      this.props.stripe
        .createToken({
          type: "card",
          name: localStorage.getItem("username"),
        })
        .then((payload) => {
          if (payload.error) {
            ToastDemo(this.props.toastManager, payload.error.message, "error");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          } else {
            const inputData = {
              card_token: payload.token.id,
            };
            api
              .postMethod("cards_add", inputData)
              .then((response) => {
                if (response.data.success) {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                  );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                    cardList: null,
                    loadingCardList: true,
                    displayAddCard: "none",
                  });
                  this.getCardList();
                } else {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.error_message,
                    "error"
                  );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                  });
                }
              })
              .catch((error) => {
                ToastDemo(this.props.toastManager, error, "error");
                this.setState({
                  loadingContent: null,
                  buttonDisable: false,
                });
              });
          }
        });
    } else {
      ToastDemo(
        this.props.toastManager,
        "Stripe.js hasn't loaded yet.",
        "error"
      );
      this.setState({ loadingContent: null, buttonDisable: false });
    }
  };
  displayAddCard = () => {
    this.setState({ displayAddCard: "block" });
  };

  setDefaultCard = (event, card) => {
    event.preventDefault();
    console.log("details ", card);
    api
      .postMethod("cards_default", { user_card_id: card.user_card_id })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getCardList();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  deleteCard = (event, card) => {
    event.preventDefault();
    api
      .postMethod("cards_delete", { user_card_id: card.user_card_id })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.getCardList();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  render() {
    const { cardList, loadingCardList, displayAddCard } = this.state;
    return (
      <section className="sm-padding bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main no-margin">{t("user_payment_method")}</h2>
              <i className="border-title mb-5"></i>
            </div>
          </div>
          <div className="user-details">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-9">
                <div className="tab-content">
                  <div className="tab-pane active" id="payment" role="tabpanel">
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <h4 className="title mb-4">{t("payment")}</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 payment-method">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              {loadingCardList
                                ? t("loading")
                                : cardList.cards.length > 0
                                ? cardList.cards.map((details) => (
                                    <div
                                      className="col-md-5"
                                      key={details.user_card_id}
                                    >
                                      <div className="paid-details">
                                        <div
                                          className="radiobtn mb-4 bg-shadow-1"
                                          onClick={(event) =>
                                            this.setDefaultCard(event, details)
                                          }
                                        >
                                          <input
                                            type="radio"
                                            name="plan"
                                            id="inline-radio-1"
                                            className="form-check-input"
                                            defaultChecked={
                                              details.is_default == 1
                                                ? true
                                                : false
                                            }
                                          />
                                          <label
                                            type="radio"
                                            for="inline-radio-1"
                                            className="form-check-label card-label"
                                          >
                                            <h3 className="payment-head-tit"></h3>
                                            <p className="desc">
                                              XXXX XXXX XXXX {details.last_four}
                                            </p>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : <div className="col-md-5 mb-3">{t("no_data_found")}</div>}

                              <div className="col-md-12 resp-mt">
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="add-payment">
                                      <a
                                        className="no-margin"
                                        href="#"
                                        onClick={this.displayAddCard}
                                      >
                                        <i className="fas fa-plus plus-icon mr-2"></i>
                                        <span className="card-option">
                                          {t("add_a_payment_method")}
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-7"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-3"
                      style={{ display: displayAddCard }}
                    >
                      <div className="col-md-10">
                        <form onSubmit={this.addCard}>
                          <div className="card-pay bg-shadow-1">
                            <h4 className="title">{t("add_a_payment_method")}</h4>
                            <div className="card-img">
                              <img
                                src="../assets/img/cards.png"
                                className="crd-img-userdetails"
                                alt="card payment"
                              />
                            </div>

                            <CardElement />

                            <div className="row mt-5">
                              <div className="col-md-12">
                                <button
                                  type="submit"
                                  lassName="btn btn-purple"
                                  disabled={this.state.buttonDisable}
                                >
                                  {this.state.loadingContent != null
                                    ? this.state.loadingContent
                                    : t("add_card")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default injectStripe(withToastManager(translate(UserPayment)));
