import React, { Component } from "react";
import { Link } from "react-router-dom";

class ProfileHeader extends Component {
  state = {};
  render() {
    return (
      <header className="profile-bg">
        <div className="overlay-bg"></div>
        <nav className="navbar navbar-expand-lg white">
          <div className="container no-padding">
            <Link className="navbar-brand" to={"/"}>
              RentStorage
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-icon"></span>
              <span className="navbar-icon"></span>
              <span className="navbar-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <a className="nav-link" href="single-list.html">
                    Location
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact-us.html">
                    Contact Us
                  </a>
                </li> */}
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle dropbtn" href="#">
                    Bookings
                  </a>
                  <div className="dropdown-content">
                    <Link to={"/upcoming-bookings"}>Upcoming Booking</Link>
                    <Link to={"/booking-history"}>Booking History</Link>
                    {/* <Link to={"/booking-transaction"}>Booking Transaction</Link> */}
                  </div>
                </li>

                <li className="nav-item dropdown user-profile">
                  <a
                    className="dropdown-toggle user-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={localStorage.getItem("user_picture")} />
                    <span className="user-nav pl-2">
                      {localStorage.getItem("username")}
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to={"/profile"}>
                      Profile
                    </Link>
                    <Link className="dropdown-item" to={"/user-payment"}>
                      Payments
                    </Link>

                    <Link className="dropdown-item" to={"/change-password"}>
                      Change Password
                    </Link>
                    <Link className="dropdown-item" to={"/delete-account"}>
                      Delete Account
                    </Link>
                    <Link className="dropdown-item" to={"/logout"}>
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default ProfileHeader;
