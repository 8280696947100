import React, { Component } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

class Footer extends Component {
  state = {
    footer_first_section: [],
    footer_second_section: [],
    footer_third_section: [],
    footer_fourth_section: [],
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Call api function

    let loading =
      configuration.get("configData") != null ||
      configuration.get("configData") != undefined
        ? false
        : true;

    if (configuration.get("configData.footer_first_section")) {
      this.setState({
        footer_first_section: configuration.get(
          "configData.footer_first_section"
        ),
      });
    }

    if (configuration.get("configData.footer_second_section")) {
      this.setState({
        footer_second_section: configuration.get(
          "configData.footer_second_section"
        ),
      });
    }

    if (configuration.get("configData.footer_third_section")) {
      this.setState({
        footer_third_section: configuration.get(
          "configData.footer_third_section"
        ),
      });
    }

    if (configuration.get("configData.footer_fourth_section")) {
      this.setState({
        footer_fourth_section: configuration.get(
          "configData.footer_fourth_section"
        ),
      });
    }
  }

  render() {
    return (
      <footer className="footer sm-padding bg-color1">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 footer_list">
              <div className="footer_logo mb-2">
                {configuration.get("configData.site_name")}
              </div>
              <p className="footer_desc">
                {configuration.get("configData.tag_name")}
              </p>
              <ul className="no-padding">
                {configuration.get("configData.footer_first_section") &&
                configuration.get("configData.footer_first_section").length > 0
                  ? configuration
                      .get("configData.footer_first_section")
                      .map((static_page, index) => (
                        <li>
                          <Link
                            to={`/page/${static_page.unique_id}`}
                            target="_blank"
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))
                  : ""}
              </ul>

              {/* <p className="footer_desc mt-5">
                <a href="#">
                  <i className="fa fa-phone mr-2"></i>1800 123 456 789
                </a>
              </p>
              <p className="footer_desc mt-2">
                <i className="far fa-building mr-2"></i>4th John St,North
                Sydney,NSW 2060.
              </p> */}
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 footer_list">
              <div className="footer_title">Discover</div>
              <ul className="no-padding">
                {configuration.get("configData.footer_second_section") &&
                configuration.get("configData.footer_second_section").length > 0
                  ? configuration
                      .get("configData.footer_second_section")
                      .map((static_page, index) => (
                        <li>
                          <Link
                            to={`/page/${static_page.unique_id}`}
                            target="_blank"
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))
                  : ""}
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 footer_list">
              <div className="footer_title">Company</div>
              <ul className="no-padding">
                {configuration.get("configData.footer_third_section") &&
                configuration.get("configData.footer_third_section").length > 0
                  ? configuration
                      .get("configData.footer_third_section")
                      .map((static_page, index) => (
                        <li>
                          <Link
                            to={`/page/${static_page.unique_id}`}
                            target="_blank"
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))
                  : ""}
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 footer_list">
              <div className="footer_title">Social</div>
              <ul className="no-padding">
                {configuration.get("configData.footer_fourth_section") &&
                configuration.get("configData.footer_fourth_section").length > 0
                  ? configuration
                      .get("configData.footer_fourth_section")
                      .map((static_page, index) => (
                        <li>
                          <Link
                            to={`/page/${static_page.unique_id}`}
                            target="_blank"
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
