import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class ForgotPassword extends Component {
  state = {};
  render() {
    return (
      <section className="login bg-color4">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="forgot-password-info">
              <Link to={"/login"}>
                <h4 className="back-btn ml-5 mt-5">
                  <i className="fas fa-long-arrow-alt-left mr-2"></i>
                  {t("back")}
                </h4>
              </Link>
              <div className=" login-left-content">
                <h3 className="title_main text-center">{t("forgot_password")}</h3>
                <form className="login-form">
                  <div className="form-group">
                    <label className="col-form-label">{t("old_password")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("old_password_placeholder")}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">{t("new_password")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("new_password_placeholder")}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      {t("confirm_new_password")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("enter_confirm_password")}
                    />
                  </div>
                  <a
                    href="#"
                    className="btn btn-purple btn-block mt-4 forgot-to-login"
                  >
                    {t("continue")}
                  </a>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="login-forgot-password full-height">
              <div className="overlay-login">
                <div className="login-right-content">
                  <a className="login-logo" href="index.html">
                    {t("site_name")}
                  </a>
                  <div className="login-details">
                    <h2 className="login-title white mb-2">
                      {t("site_para1")}
                    </h2>
                    <p className="login-desc mt-3 white tex-justify">
                      {t("site_para2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default translate(ForgotPassword);
