import React, { Component } from "react";

// This Layouts used in Home page Header,  floating footer and content

class AuthLayout extends Component {
  constructor(props) {
    super(props);
    this.eventEmitter = this.props.screenProps;
  }

  render() {
    return (
      <div className="wrapper">
        <div classname="main-wrapper">
          {React.cloneElement(this.props.children, {
            eventEmitter: this.eventEmitter
          })}
        </div>
      </div>
    );
  }
}
export default AuthLayout;
