import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";


class Profile extends Helper {
  state = {
    data: null,
    loading: true,
    profileError: null
  };

  componentDidMount() {
    this.getUserDetails();
  }
  render() {
    const { data, loading } = this.state;
    return (
      <section className="sm-padding-div bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-box row xs-padding-div">
                <div className="col-md-5">
                  <div>
                    <img className="profile-dp" src={loading ? "loading..." : data.picture} />
                  </div>
                </div>
                <div className="col-md-7 padding-left">
                  <div className="profile-name border-bottom">
                    <p className="user-name title_main">Welcom to Profile</p>
                  </div>
                  {loading ? (
                    "Loading..."
                  ) : (
                    <div className="personal-information xs-padding">
                      <p>
                        <label>
                          {t("name")} : <span>{data.name}</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          {t("email")} : <span>{data.email}</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          {t("mobile")} : <span>{data.mobile}</span>
                        </label>
                      </p>
                      <p>
                        <label>
                          {t("description")} : <span>{data.description}</span>
                        </label>
                      </p>
                    </div>
                  )}
                  <Link
                    to={{
                      pathname: "/edit-profile",
                      state: data
                    }}
                    className="btn btn-purple"
                  >
                    {t("edit_profile")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(Profile));
