import React, { Component } from "react";

class SingleSpaceOpenTimings extends Component {
  state = {
    space: null,
    loadingSpace: true
  };
  render() {
    const { space, loadingSpace } = this.props.space;

    return (
    
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 margin-bottom">
              <h2 className="title_main no-margin">Our Opening Hours</h2>
              <i className="border-title mb-5"></i>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Days</th>
                    <th scope="col" className="text-center">
                      Opening
                    </th>
                    <th scope="col" className="text-center">
                      Closing
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.data.length > 0
                    ? this.props.data.map(opening => (
                        <tr>
                          <th scope="row">{opening.day}</th>
                          <td>{opening.in_time}</td>

                          <td>{opening.out_time}</td>
                        </tr>
                      ))
                    : "No data found"}
                </tbody>
              </table>
            </div>
            {loadingSpace
              ? "Loading..."
              : space 
              ? 
            <div className="col-md-6 mb-5">
              <div className="row">
              {space.length > 0
                      ? space.map(space => (
                <div className="col-md-12 mb-4">
                  <div className="workspace-card">
                    <a href={`/single-space/${space.space_id}`}>
                      <div className="card_img left_card_img">
                        <img src={space.space_picture} alt="" />
                      </div>
                      <div className="right_card_content">
                        <h4 className="title">{space.space_name}</h4>
                        <p className="desc mb-2">
                          <strong>{space.dimension}</strong>
                        </p>
                        <p className="desc single_space_suggession_desc">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: space.description ? space.description : "N/A",
                            }}
                          />
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                ))
                : "No data found"}
              </div>
            </div>
          : ""}
          </div>
        </div>
      </section>
      
    );
  }
}

export default SingleSpaceOpenTimings;
