import React, { Component } from "react";
import dayjs from "dayjs";
import api from "../../Environment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import ToastDemo from "../Helper/toaster";

var yesterday = moment().subtract( 1, 'day' );
var valid = function( current ){
    return current.isAfter( yesterday );
};

class BookingForm extends Component {
  state = {
    priceTypeText: "Days/Months",
    formData: {
      checkin: "",
      checkout: ""
    },
    pricing: null,
    loading: true,
    pricingCalculatorInput: {
      price_type: "",
      total_days: "",
      no_of_units: "",
      checkin: "",
      space_id: ""
    },
    loadingText: "",
    buttonDisable: true,
    buttonLoadingText: null
  };
  
  componentDidMount() {
    this.getDefaultLeavingTime();
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    pricingCalculatorInput["space_id"] = this.props.basicDetails.space_id;
    this.setState({ pricingCalculatorInput });
  }

  handleChange = ({ currentTarget: input }) => {
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    pricingCalculatorInput[input.name] = input.value;
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  checkValidation = () => {
    console.log(this.state.pricingCalculatorInput);
    if (this.state.pricingCalculatorInput["checkin"] != "") {
      if (this.props.basicDetails.space_type == "shared_space") {
        if (
          this.state.pricingCalculatorInput["price_type"] == "per_day" &&
          this.state.pricingCalculatorInput["total_days"] != "" &&
          this.state.pricingCalculatorInput["no_of_units"] != ""
        ) {
          this.setState({ loadingText: "Loading..." });
          this.pricingCalculator();
          // setTimeout(() => {
          //   this.pricingCalculator();
          // }, 1000);
          return true;
        } else if (
          this.state.pricingCalculatorInput["price_type"] == "per_month" &&
          this.state.pricingCalculatorInput["no_of_units"] != ""
        ) {
          this.setState({ loadingText: "Loading..." });
          this.pricingCalculator();

          return true;
        } else {
          return null;
        }
      } else {
        this.setState({ loadingText: "Loading..." });
        this.pricingCalculator();

        return true;
      }
    } else {
      return null;
    }
  };

  pricingCalculator() {
    this.setState({
      buttonDisable: true,
      buttonLoadingText: "Loading... Please wait."
    });
    api
      .postMethod("price_calculator", this.state.pricingCalculatorInput)
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          this.setState({
            pricing: response.data.data,
            loading: false,
            loadingText: "",
            buttonDisable: false,
            buttonLoadingText: null
          });
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({
            loadingText: "",
            buttonDisable: true,
            buttonLoadingText: null
          });
        }
      });
  }

  getArrivingDateandTime = title => (...args) => {
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    const datess = dayjs(args[0]).format("YYYY-MM-DD HH:mm:ss");
    pricingCalculatorInput["checkin"] = datess;
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  getDefaultLeavingTime = () => {
    var oldDateObj = new Date();
    var newDateObj = new Date();
    newDateObj.setTime(oldDateObj.getTime() + 60 * 60 * 1000);

    return dayjs(newDateObj).format("YYYY-MM-DD HH:mm");
  };
  getDefaultArravingTime = () => {
    return dayjs(new Date()).format("YYYY-MM-DD HH:mm");
  };

  priceType = value => {
    this.setState({ priceTypeText: value });
    const pricingCalculatorInput = { ...this.state.pricingCalculatorInput };
    if (value == "Days") pricingCalculatorInput["price_type"] = "per_day";
    else pricingCalculatorInput["price_type"] = "per_month";
    this.setState({ pricingCalculatorInput });
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  };

  sendCheckout = event => {
    event.preventDefault();
    const value = this.checkValidation();
    if (value != null && this.state.pricing != null) {
      if (localStorage.getItem("userLoginStatus"))
        this.props.props.history.push("/payment", {
          pricing: this.state.pricing,
          basicDetails: this.props.basicDetails,
          pricingCalculatorInput: this.state.pricingCalculatorInput
        });
      else
        ToastDemo(this.props.toastManager, "Please login to continue", "error");
    } else {
      ToastDemo(this.props.toastManager, "Please fill all the details", "error");
    }
  };

  render() {
    let inputProps = {
      placeholder: 'Select Date',
    };
    const {
      priceTypeText,
      loading,
      pricing,
      pricingCalculatorInput,
      loadingText,
      buttonDisable,
      buttonLoadingText
    } = this.state;
    return (
      <div className="col-md-5">
        <div className="form-contact">
          <form>
            <div class="form-row">
              <div className="form-group col-md-6">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="perday"
                    name="price"
                    value="per_day"
                    onClick={() => this.priceType("Days")}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" for="perday">
                    Per Day
                  </label>
                </div>
              </div>

              <div className="form-group col-md-6">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="permonth"
                    name="price"
                    value="per_month"
                    className="custom-control-input"
                    onClick={() => this.priceType("Months")}
                  />
                  <label className="custom-control-label" for="permonth">
                    Per Month
                  </label>
                </div>
              </div>
            </div>

            {priceTypeText == "Days" ? (
              <div className="form-group">
                <label>How many days</label>
                <input
                  type="number"
                  className="form-control"
                  name="total_days"
                  placeholder={priceTypeText}
                  value={pricingCalculatorInput["total_days"]}
                  onChange={this.handleChange}
                />
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              <label>How many Units</label>
              <input
                type="number"
                className="form-control"
                name="no_of_units"
                placeholder="No of Units"
                value={pricingCalculatorInput["no_of_units"]}
                onChange={this.handleChange}
              />
            </div>

            <div class="form-row">
              <div className="form-group col-md-6">
                <label>Check In Date</label>
                {/* <input type="phone" placeholder="Date" /> */}
                <Datetime
                  // initialValue={this.getDefaultArravingTime()}
                  onChange={this.getArrivingDateandTime("Range DatePicker")}
                  closeOnSelect={ true }
                  isValidDate={ valid }
                  dateFormat="DD-MM-YYYY"
                  showDefaultIcon
                  inputProps={inputProps}
                />
              </div>

              {/* <div className="form-group col-md-6">
                <button className="btn btn-primary">Calculate</button>
              </div> */}
            </div>

            {loading ? (
              ""
            ) : (
              <React.Fragment>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Sub Total</label>
                  </div>
                  <div className="col-md-6">
                    <label>
                      {loadingText != ""
                        ? loadingText
                        : pricing.sub_total_formatted}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Tax Price</label>
                  </div>
                  <div className="col-md-6">
                    <label>
                      {loadingText != ""
                        ? loadingText
                        : pricing.tax_price}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Total Amout payable</label>
                  </div>
                  <div className="col-md-6">
                    <label>
                      {loadingText != ""
                        ? loadingText
                        : pricing.total_formatted}
                    </label>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div className="row">
              <div className="col-md-12 mt-3">
                <Link
                  to='#'
                  onClick={this.sendCheckout}
                  disable={buttonDisable}
                  className="btn btn-purple btn-lg btn-block"
                >
                  {buttonLoadingText != null ? buttonLoadingText : "Book Now"}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withToastManager(BookingForm);
