import React, { Component } from "react";
import SimilarListing from "./SimilarListing";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { withToastManager } from "react-toast-notifications";

import { apiConstants } from "../Constant/constants";
import SingleSpaceListingReview from "./SingleSpaceListingReview";
import SingleSpaceAmenities from "./SingleSpaceAmenities";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";
import SingleSpaceMap from "./SingleSpaceMap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SingleSpaceOpenTimings from "./SingleSpaceOpenTimings";
import BookingForm from "./BookingForm";

class SingleSpaceListing extends Component {
  state = {
    loading: true,
    data: null
  };

  componentDidMount() {
    const inputData = {
      space_id: this.props.match.params.id
    };
    this.singleSpaceViewAPI(inputData);
    this.getSimilarLocations(inputData.space_id);
    this.getSimilarSpace(inputData.space_id);
  }

  singleSpaceViewAPI = inputData => {
    api.postMethod("spaces_view", inputData).then(response => {
      if (response.data.success) {
        this.setState({
          loading: false,
          data: response.data.data
        });
      } else {
        this.props.history.push("/");
        ToastDemo(
          this.props.toastManager,
          "Space not found, Please try again.",
          "error"
        );
      }
    });
  };

  getSimilarLocations = (space_id) => {
    
    api.postMethod("home_similar_spaces", { space_id: space_id, take: 3 }).then(response => {
      if (response.data.success === true) {
        this.setState({ serviceLocation: response.data.data, loadingServiceLocation: false });
      } else {
        // Do nothing.
      }
    });
  };

  getSimilarSpace = (space_id) => {
    
    api.postMethod("spaces_home_similar_spaces", { space_id: space_id, take: 3 }).then(response => {
      if (response.data.success === true) {
        this.setState({ space: response.data.data, loadingSpace: false });
      } else {
        // Do nothing.
      }
    });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <div>
        <section className="sm-padding">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <h2 className="title_main">Storage</h2>
                <i className="border-title"></i>
              </div>
            </div>
            {loading ? (
              "Loading..."
            ) : (
              <div className="row">
                <div className="col-md-7 margin-bottom">
                  <div className="row">
                    <div className="col-md-12 single-storage">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <i className="fas fa-map-marker-alt mr-2 color-1"></i>
                            Storage Location
                          </a>
                        </li>
                        <li className="nav-item ml-2">
                          <a
                            className="nav-link"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            <i className="fas fa-camera mr-2"></i>Photo Gallery
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div
                            className="map single-map-list mt-4"
                            style={{ width: "100%", height: "200px" }}
                          >
                            {loading ? (
                              "Loading..."
                            ) : (
                              <SingleSpaceMap data={data.basic_details} />
                            )}
                          </div>
                          <div className="gallery-content pt-4">
                            <p className="desc">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.basic_details.space_description ? data.basic_details.space_description : "-",
                              }}
                            />
                            </p>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div
                            id="carouselExampleFade"
                            className="carousel slide carousel-fade mt-4"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner gallery-card">
                              <Carousel
                                showThumbs={false}
                                infiniteLoop={true}
                                showStatus={false}
                              >
                                {loading
                                  ? "Loading..."
                                  : data.space_gallery.length > 0
                                  ? data.space_gallery.map(image => (
                                      <div className="">
                                        <img
                                          src={image.picture}
                                          className="d-block w-100"
                                          alt="..."
                                        />
                                      </div>
                                    ))
                                  : ""}
                              </Carousel>
                            </div>

                            <div className="gallery-content pt-4">
                              <p className="desc">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.basic_details.space_description ? data.basic_details.space_description : "-",
                                }}
                              />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <BookingForm
                  props={this.props}
                  basicDetails={data.basic_details}
                />
              </div>
            )}
          </div>
        </section>
        {loading ? (
          "Loading..."
        ) : (
          <React.Fragment>
            <section className="sm-padding">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-md-12 mt-4">
                    <h2 className="title_main">Workspace Pricing</h2>
                    <i className="border-title"></i>
                    <div className="row mt-4">
                      <div className="col-md-6 mb-5">
                        <div className="workspace-card only-pricing p-3">
                          <a href="#">
                            <p className="card-desc border-left-price mb-2">
                              <span>Per Day</span>
                              <strong>
                                <span className="float-right">
                                  {data.basic_details.per_day_formatted}
                                </span>
                              </strong>
                            </p>
                            <p className="card-desc no-margin border-left-price">
                              Per Month{" "}
                              <strong>
                                <span className="float-right">
                                  {data.basic_details.per_month_formatted}
                                </span>
                              </strong>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  {data.amenties.data.length > 0 ? (
                    <SingleSpaceAmenities amenties={data.amenties} />
                  ) : (
                    ""
                  )}

                  <SingleSpaceListingReview data={data.basic_details} />

                  <SingleSpaceOpenTimings data={data.space_opening_timings} space={this.state}/>
              </div>
            </section>
          </React.Fragment>
        )}
        <SimilarListing data={this.state}/>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyARW_YBJ-OU_RfSlMLlvLBHJaG-W_EQv4I"
})(withToastManager(SingleSpaceListing));
