import React, { Component } from "react";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";

class HomePageBanner extends Component {
  state = {
    formData: {
      latitude: "",
      longitude: ""
    }
  };

  displaySpaces = event => {
    event.preventDefault();
    console.log("props", this.props.props);
    if (!this.state.formData.full_address) {
      ToastDemo(this.props.toastManager, "Enter the location", "error");
      return false;
    }

    this.props.props.history.push("/view-all", this.state.formData);
  };

  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      // console.log("Place", place);
      // if (!place.geometry) return;
      // this.setState({ position: place.geometry.location });
      const formData = { ...this.state.formData };
      formData["latitude"] = place.geometry.location.lat();
      formData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        address =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      formData["full_address"] = full_address;

      this.setState({ formData });
    });
  };
  render() {
    return (
      <section
        className="banner-img-container banner-bg"
        style={{
          backgroundImage: `url(../assets/img/banner2.jpg)`
        }}
      >
        <div className="overlay-bg"></div>
        <div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="banner-details">
                <h2 className="banner-title white mb-2">
                  Full Storage Service Near You
                </h2>
                <p className="banner-desc white">
                  Get instant access to reliable, on demand storage space at
                  your convenience.
                </p>
                <div className="location-search mt-4">
                  <form onSubmit={this.displaySpaces}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search location"
                        onFocus={this.renderAutoComplete}
                        ref={ref => (this.autocomplete = ref)}
                      />
                      <div className="input-group-append ml-3">
                        <button className="banner-search-btn">
                          <span className="input-group-text">Search</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(withToastManager(HomePageBanner));
