import React, { Component } from "react";

class HomePageStaticContent extends Component {
  state = {};
  render() {
    return (
      <div>
        <section className="sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">
                  Personal,Business and Vechicle Storage.
                </h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5 justify-content-md-center">
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md margin-bottom">
                <div className="card-custom">
                  <div className="card-img">
                    <img src="../assets/img/storage1.jpg" alt="" />
                  </div>
                  <div className="card-details">
                    <h4 className="title">
                      Have more belongings than you have space?
                    </h4>
                    <p className="desc">
                      Store your occasionally used belongings in our personal
                      storage units.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md margin-bottom">
                <div className="card-custom">
                  <div className="card-img">
                    <img src="../assets/img/storage2.jpg" alt="" />
                  </div>
                  <div className="card-details">
                    <h4 className="title">
                      Business Looking for short term storage?
                    </h4>
                    <p className="desc">
                      Store your stock in our on-demand warehouse facility.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="card-custom">
                  <div className="card-img">
                    <img src="../assets/img/storage3.jpg" alt="" />
                  </div>
                  <div className="card-details">
                    <h4 className="title">Automobile / Vechicle Storage?</h4>
                    <p className="desc">
                      We provide regular maintenance for your vehicles and
                      provides clean storage place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6" id="storage">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">How Its Work</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xl-3 col-lg-3 col-md-6 resp-margin-btm-md margin-bottom text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-truck font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">Welcome to you</h4>
                    <p className="desc">
                      Our pro movers will come for the pickup. We’ll wrap, do
                      the heavy lifting and handle your belongings with utmost
                      care.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 resp-margin-btm-md margin-bottom text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-warehouse font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">Safe storage</h4>
                    <p className="desc">
                      We’ll then move your belongings to secure self storage
                      unit in our safe storage facility that is monitored 24/7.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 margin-bottom text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-list-ul font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">Keep tabs on your stuff</h4>
                    <p className="desc">
                      We photograph and catalog your belongings so you know
                      what’s stored and can easily select an item for return.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-history font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">Easy returns</h4>
                    <p className="desc">
                      Select a few or all of your belongings and we'll have them
                      at your doorstep within 72 hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">Need a Quick Query ?</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="contact_icon_box">
                      <i className="fas fa-mobile-alt font-animate"></i>
                      <div className="right-details">
                        <h4 className="title">Call Us</h4>
                        <p className="desc">
                          <a href="" className="text-success">
                            +91 9876543210
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact_icon_box">
                      <i className="fas fa-envelope font-animate"></i>
                      <div className="right-details">
                        <h4 className="title">Email Us</h4>
                        <p className="desc">
                          <a href="" className="text-success">
                            contact@rentstorage.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact_icon_box border-none">
                      <i className="fas fa-map-marker-alt font-animate"></i>
                      <div className="right-details">
                        <h4 className="title">Office</h4>
                        <p className="desc">
                          No.20, Link road, MT Circle Bengaluru.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-form">
                  <h3 className="title mb-4">Make a request now.</h3>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            name=""
                            className="form-control"
                            placeholder="Enter name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="text"
                            name=""
                            className="form-control"
                            placeholder="Enter mobile number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select storage services</label>
                          <div className="custom-select border-none">
                            <select>
                              <option value="0">Storage service</option>
                              <option value="1">Personal Storage</option>
                              <option value="2">Business Storage</option>
                              <option value="3">Vechicle Storage</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Messages</label>
                          <textarea
                            className="form-control"
                            placeholder="What’s Up"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <a href="#" className="btn btn-purple">
                          Request a call back
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="list-work sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">Benefits for the host</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5 justify-content-md-center">
                <div className="col-xl-4 col-lg-4 col-md-6 margin-bottom resp-margin-btm-md">
                  <div className="card-content text-center">
                    <div className="icon mb-4">
                      <i className="far fa-list-alt font-animate"></i>
                    </div>
                    <div className="card-info">
                      <h4 className="title">List Your Spaces</h4>
                      <p className="desc">
                        It’s free and only takes 5 minutes! Be as detailed as
                        possible, add photos and outline any rules you would
                        like to set.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 margin-bottom resp-margin-btm-md">
                  <div className="card-content text-center">
                    <div className="icon mb-4">
                      <i className="far fa-calendar-alt font-animate"></i>
                    </div>
                    <div className="card-info">
                      <h4 className="title">List Your Booking</h4>
                      <p className="desc">
                        We advertise your space for you. Accept a reservation
                        request that suits you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="card-content text-center">
                    <div className="icon mb-4">
                      <i className="fas fa-dollar-sign font-animate"></i>
                    </div>
                    <div className="card-info">
                      <h4 className="title">Earn Money</h4>
                      <p className="desc">
                        Payment for the month will be made into your bank
                        account within 24 hrs of your Renter moving in, and then
                        automatically each month until the end of the rental.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <section className="sm-padding bg-color3">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="title_main white">
                  Yes, I need your help with storage
                </h3>
                <p className="desc white">
                  Contact us by phone or email to find out what we offer.
                </p>
              </div>
              <div className="col-md-4 text-right align-center mt-4">
                <a href="contact-us.html" className="btn btn-black">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default HomePageStaticContent;
