import React, { Component } from "react";
import ReactTimeout from "react-timeout";

import { Router, Switch, Route, Redirect } from "react-router-dom";

// import createHistory from "history/createBrowserHistory";

import { createBrowserHistory as createHistory } from "history";

import { ToastProvider } from "react-toast-notifications";

import { Elements, StripeProvider } from "react-stripe-elements";

import configuration from "react-global-configuration";
import { apiConstants } from "./components/Constant/constants";

// import files.

import FirstLayout from "./components/Layouts/firstLayout";
import LandingPage from "./components/Home/LandingPage";
import Login from "./components/Auth/Login";
import AuthLayout from "./components/Layouts/AuthLayout";
import Signup from "./components/Auth/Signup";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ViewAll from "./components/Listing/ViewAll";
import SingleSpaceListing from "./components/Listing/SingleSpaceListing";
import EditProfile from "./components/Account/EditProfile";
import ProfileLayout from "./components/Layouts/ProfileLayout";
import Profile from "./components/Account/Profile";
import Logout from "./components/Account/Logout";
import BusinessStorage from "./components/StaticPages/BusinessStorage";
import PersonalStorage from "./components/StaticPages/PersonalStorage";
import VehicleStorage from "./components/StaticPages/VehicleStorage";
import DeleteAccount from "./components/Account/DeleteAccount";
import ChangePassword from "./components/Account/ChangePassword";
import UserPayment from "./components/Account/UserPayment";
import UpcomingBookings from "./components/Bookings/UpcomingBookings";
import SingleBookingHistory from "./components/Bookings/SingleBookingHistory";
import BookingTransaction from "./components/Bookings/BookingTransaction";
import BookingHistory from "./components/Bookings/BookingHistory";
import PaymentPage from "./components/Listing/PaymentPage";
import jsonData from './components/settings.json';

import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "./components/translation/en.json";
import pt from "./components/translation/pt.json";
import StaticPage from "./components/StaticPages/StaticPage";
setTranslations({ pt, en });
const history = createHistory();

const $ = window.$;
const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout screenProps={ScreenProps}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);
const HostPrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  host_authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      host_authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const UserPrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  user_authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      user_authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
class App extends Component {
  constructor(props) {
    super(props);

    // user_Authentication

    let userId = localStorage.getItem("userId");

    let providerId = localStorage.getItem("hostId");

    let accessToken = localStorage.getItem("accessToken");

    this.state = {
      loading: true,
      configLoading: true,
      user_authentication: userId && accessToken ? true : false,
      host_authentication: providerId && accessToken ? true : false
    };

    // this.eventEmitter = new Emitter();

    // configuration.set({
    //   configData: configData.data
    // });

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");
      providerId = localStorage.getItem("hostId");
      accessToken = localStorage.getItem("accessToken");

      // this.setState({ loading : true, user_authentication : userId && accessToken ? true : false});

      this.setState({ loading: true, user_authentication: true });
      this.setState({ loading: true, host_authentication: true });

      // this.loadingFn();

      document.body.scrollTop = 0;
    });
    configuration.set({
      configData: jsonData
    }, { freeze: false });
    this.fetchConfig();
  }

  async fetchConfig() {
    const response = await fetch(apiConstants.settingsUrl);
    console.log("respi", response);
    const configValue = await response.json();
    configuration.set({
      configData: configValue.data
    });
    this.setState({ configLoading: false });
  }

  loadingFn() {
    this.props.setTimeout(() => {
      this.setState({ loading: false });
    }, 3 * 1000);
  }

  loadConfigData() {}

  componentDidMount() {
    this.loadConfigData(); // Not used

    let userLanguage = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en";
        console.log(userLanguage);
        localStorage.setItem("lang", userLanguage);
        setLanguage(userLanguage);
  }
  componentWillMount() {
    // document.title = configData.data.site_name;
  }

  render() {
    /*const isLoading = this.state.loading;
      
      if (isLoading) {

        return (
          <div className="wrapper">
            <div className="loader-warpper">
                <div id="loader">
                  <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                  </ul>
                </div>
            </div>
          </div>
        );

      } */

    return (
      <ToastProvider>
        <Router history={history}>
          <Switch>
            {/*** Layout 1 - Transparent Fixed Header and Floating Footer ****/}
            <AppRoute
              exact
              path={"/"}
              component={LandingPage}
              layout={FirstLayout}
            />
            <AppRoute
                path={"/page/:id"}
                component={StaticPage}
                layout={FirstLayout}
                screenProps={this.eventEmitter}
              />
            <AppRoute
              path={"/login"}
              component={Login}
              layout={AuthLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/signup"}
              component={Signup}
              layout={AuthLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/forgot-password"}
              component={ForgotPassword}
              layout={AuthLayout}
              screenProps={this.eventEmitter}
            />

            <Route path={"/logout"} component={Logout} />

            <AppRoute
              path={"/view-all"}
              component={ViewAll}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/single-space/:id"}
              component={SingleSpaceListing}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/payment"}
              component={PaymentPage}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/business-storage"}
              component={BusinessStorage}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/personal-storage"}
              component={PersonalStorage}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <AppRoute
              path={"/vehicle-storage"}
              component={VehicleStorage}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />

            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/profile"}
              component={Profile}
              layout={ProfileLayout}
              screenProps={this.eventEmitter}
            />

            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/edit-profile"}
              component={EditProfile}
              layout={ProfileLayout}
              screenProps={this.eventEmitter}
            />
            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/delete-account"}
              component={DeleteAccount}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/change-password"}
              component={ChangePassword}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />

            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/upcoming-bookings"}
              component={UpcomingBookings}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />
            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/booking-history"}
              component={BookingHistory}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />

            <UserPrivateRoute
              user_authentication={this.state.user_authentication}
              path={"/single-booking-history/:id"}
              component={SingleBookingHistory}
              layout={FirstLayout}
              screenProps={this.eventEmitter}
            />

            <StripeProvider apiKey="pk_test_uDYrTXzzAuGRwDYtu7dkhaF3">
              <Elements>
                <UserPrivateRoute
                  user_authentication={this.state.user_authentication}
                  path={"/user-payment"}
                  component={UserPayment}
                  layout={FirstLayout}
                  screenProps={this.eventEmitter}
                />
              </Elements>
            </StripeProvider>

            {/**
             *  For private route
             * <UserPrivateRoute user_authentication={this.state.user_authentication} path={"/subscriptions"} component={Subscription} layout={TwoColumnLayout} screenProps={this.eventEmitter}/>
             */}
          </Switch>
        </Router>
      </ToastProvider>
    );
  }
}

export default ReactTimeout(App);
