import React, { Component } from "react";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { DatePicker } from "@y0c/react-datepicker";
import dayjs from "dayjs";
// import { apiConstants } from "../../components/Constant/constants";

import configuration from "react-global-configuration";
import HomePageBanner from "./HomePageBanner";
import HomePageStaticContent from "./HomePageStaticContent";

class LandingPage extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    second_block: null,
    formData: {
      checkin: "",
      checkout: "",
      latitude: "",
      longitude: ""
    }
  };
  constructor(props) {
    super(props);

    // States and props usage
  }

  componentDidMount() {
    // Call api function
  }

  render() {
    return (
      <div>
        <HomePageBanner props={this.props} />
        <HomePageStaticContent />
      </div>
    );
  }
}

export default withToastManager(LandingPage);
