import React, { Component } from "react";

import MapContainer from "../Helper/mapHelper";

import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { withToastManager } from "react-toast-notifications";
import { apiConstants } from "../Constant/constants";
import SimilarListing from "./SimilarListing";
import api from "../../Environment";
import SingleSpaceContent from "./SingleSpaceContent";
import { Link } from "react-router-dom";
import ToastDemo from "../Helper/toaster";

class ViewAll extends Component {
  state = {
    data: null,
    loading: true,
    loadingStatus: true,
    skipCount: 0,
    inputData: {
      latitude: "",
      longitude: "",
      full_address: "",
    },
    lastLat: null,
    lastLng: null,
  };

  componentDidMount() {
    if (this.props.location.state != null) {
      const inputData = { ...this.state.inputData };
      inputData["latitude"] = this.props.location.state.latitude;
      inputData["longitude"] = this.props.location.state.longitude;
      inputData["full_address"] = this.props.location.state.full_address;
      this.setState({ inputData });
      setTimeout(() => {
        this.homeMapSearchApi(inputData);
        this.getSimilarLocations(inputData["latitude"],inputData["longitude"]);
      }, 1000);
    } else {
      this.props.history.push("/");
    }
  }

  displaySpaces = (event) => {
    event.preventDefault();
    this.setState({ data: null, loading: true });
    if (!this.state.inputData.full_address) {
      ToastDemo(this.props.toastManager, "Enter the location", "error");
      return false;
    }
    this.homeMapSearchApi(this.state.inputData);
  };

  renderAutoComplete = () => {
    const { google } = this.props;
    if (!google) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocomplete,
      { types: ["geocode"] }
    );

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      // console.log("Place", place);
      // if (!place.geometry) return;
      // this.setState({ position: place.geometry.location });
      const inputData = { ...this.state.inputData };
      inputData["latitude"] = place.geometry.location.lat();
      inputData["longitude"] = place.geometry.location.lng();
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      inputData["full_address"] = full_address;

      this.setState({ inputData });
    });
  };

  loadMore = (event) => {
    event.preventDefault();
    let inputData = {
      latitude: this.props.location.state.latitude,
      longitude: this.props.location.state.longitude,
      skip: this.state.skipCount,
    };
    this.homeMapSearchApi(inputData);
  };

  homeMapSearchApi = (inputData) => {
    let items;
    
    api.postMethod("spaces_home_map", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.data != null) {
          items = [...this.state.data, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        this.setState({
          data: items,
          loading: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadingStatus: true,
        });
      }
    });
  };

  getSimilarLocations = (lat, long) => {
    
      api.postMethod("home_similar_spaces", { latitude: lat, longitude: long, take: 3 }).then(response => {
        if (response.data.success === true) {
          this.setState({ serviceLocation: response.data.data, loadingServiceLocation: false });
        } else {
          // Do nothing.
        }
      });
    
  };

  getListForLatLng = (lat, lng) => {
    console.log("Map moved");
    this.setState({
      skipCount: 0,
      contentData: null,
      loading: true,
      lastLat: lat,
      lastLng: lng,
    });
    const inputData = { ...this.state.inputData };
    inputData["latitude"] = lat;
    inputData["longitude"] = lng;
    this.setState({ inputData });
    setTimeout(() => {
      this.homeMapSearchApi(inputData);
    }, 1000);
    // Need to call see all API.
  };

  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <section className="sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h2 className="title_main">
                  Space in {this.state.inputData.full_address}
                </h2>
                <p className="desc">
                  Explore some of the best-reviewed storage-space in the world
                </p>
                {loading ? (
                  "Loading..."
                ) : data.length > 0 ? (
                  <React.Fragment>
                    <SingleSpaceContent data={data} />

                    <Link
                      className="btn btn-purple"
                      to={"/"}
                      onClick={this.loadMore}
                    >
                      Load More
                    </Link>
                  </React.Fragment>
                ) : (
                  "No Data Found"
                )}
              </div>

              <div className="col-md-4">
                <div className="single-list-map">
                  <div className="location-search mt-4">
                    <form onSubmit={this.displaySpaces}>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-map-marker-alt"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search location"
                          onFocus={this.renderAutoComplete}
                          ref={(ref) => (this.autocomplete = ref)}
                        />
                        <div className="input-group-append ml-3">
                          <button className="banner-search-btn">
                            <span className="input-group-text">Search</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="map single-map-list mt-4"
                    style={{ width: "100%", height: "500px" }}
                  >
                    <MapContainer
                      data={data}
                      onMapPanned={this.getListForLatLng}
                      lastLat={this.state.lastLat}
                      lastLng={this.state.lastLng}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SimilarListing data={this.state}/>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(withToastManager(ViewAll));
