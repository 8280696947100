import React, { Component } from "react";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class ChangePassword extends Helper {
  state = {
    data: {},
    loadingContent: null,
    buttonDisable: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });

    api.postMethod("change_password", this.state.data).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api.postMethod("delete_account", this.state.data).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");

        this.setState({ loadingContent: null, buttonDisable: false });
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLoginStatus");
        localStorage.removeItem("user_picture");
        localStorage.removeItem("username");
        window.location = "/";
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };
  render() {
    const { data } = this.state;
    return (
      <section className="sm-padding bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <img
                  src="../assets/img/change-password.svg"
                  alt=""
                  className="changed"
                />
              </div>
              <div className="row">
                <div className="col-md-6 mx-auto">
                  <div className="contact mt-5">
                    <form
                      className="login-form p-0"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="form-group">
                        <label>{t("old_password")}</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder={t("old_password_placeholder")}
                          value={data.old_password}
                          name="old_password"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("new_password")}</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder={t("new_password_placeholder")}
                          name="password"
                          value={data.password}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("confirm_password")}</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password_confirmation"
                          placeholder={t("confirm_address_placeholder")}
                          value={data.password_confirmation}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <button
                            disabled={this.state.buttonDisable}
                            className="btn btn-purple btn-block"
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : t("reset_password")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(ChangePassword));
