import React, { Component } from "react";

class BusinessStorage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <section
          className="banner-img-container banner-bg"
          style={{ backgroundImage: `url(../assets/img/banner3.jpg)` }}
        >
          <div className="overlay-bg"></div>
          <div>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-details">
                  <h2 className="banner-title white mb-2">
                    On Demand Business Storage Facility
                  </h2>
                  <p className="banner-desc white">
                    Get instant access to reliable, on demand business storage
                    warehouse space at your convenience.
                  </p>
                  <a href="single-list.html" className="btn btn-purple mt-4">
                    Get Estimate Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding" id="storage">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">We Make Storage Easy</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5 justify-content-md-center">
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md margin-bottom text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-truck font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We come to you and do heavy lifting
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md margin-bottom text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-warehouse font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We secure it in our storage facility
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-history font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We bring it back whenever you want
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/box.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Box Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>On Demand box storage
                      space
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Secure and isolated units
                      for your stock
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Pickup and deliver it to
                      your place
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Your business needs storage space for expansion, to handle
                    seasonal demands or reserve stock, We are proud to provide
                    our Business storage solution for your stock. We understand
                    that storage space is critical when it comes to your
                    business. Our on-demand Box storage provides assured storage
                    space and delivery on demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6 resp-order-changes">
                <h3 className="title_main">Document Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Are you looking for secure
                      storage for your business documents?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Are you looking for secure
                      storage for your archives?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Are you looking for secure
                      storage for your tax records or legal files?
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Document storage service has enabled over 100 companies to
                    effectively realise the benefits of offsite storage. Whether
                    you are looking to store your archives off-site, need a safe
                    space for confidential documentation or wish to securely
                    store your digital storage devices.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/doc.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/warehouse.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Warehouse Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>On Demand warehouse space
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Short term warehouse
                      rental
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Expand and Shrink based on
                      your needs
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Growing Business needs flexible warehouse storage space and
                    without long term locking commitments. That’s where our on
                    demand warehouse storage can help rent warehouse storage and
                    increase or decrease space, based on your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color3">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="title_main white">
                  Yes, I need your help with storage
                </h3>
                <p className="desc white">
                  Contact us by phone or email to find out what we offer.
                </p>
              </div>
              <div className="col-md-4 align-center text-right mt-4">
                <a href="contact-us.html" className="btn btn-black">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default BusinessStorage;
