import React, { Component } from "react";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import { translate, t } from "react-multi-lang";
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;


class Login extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      device_type: "web",
      timezone: const_time_zone
    },
    loadingContent: null,
    buttonDisable: false
  };

  handleSubmit = event => {
    event.preventDefault();
    const { path } = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    api
      .postMethod("login", this.state.data)
      .then(response => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);

          window.location = path ? path.from.pathname : "/";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContent: null,
            buttonDisable: false
          });
        } else {
          this.setState({
            loadingContent: null,
            buttonDisable: false
          });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(error => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };
  render() {
    const { data } = this.state;
    return (
      <section className="login bg-color4">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="login-left-content login-info">
              <h3 className="title_main text-center">{t("login")}</h3>
              <p className="text-center mb-2 desc">
                {t("or")}
                <a href="/signup" className="login-link ml-1">
                  {" "}
                  {t("create_account")}
                </a>
              </p>
              <form className="login-form" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label className="col-form-label">{t("email_address")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("email_address_placeholder")}
                    name="email"
                    onChange={this.handleChange}
                    value={data.email}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">{t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder={t("password_placeholder")}
                    name="password"
                    onChange={this.handleChange}
                    value={data.password}
                  />
                </div>
                <button
                  disabled={this.state.buttonDisable}
                  className="btn btn-purple btn-block mt-4 mb-3"
                >
                  {this.state.loadingContent != null
                    ? this.state.loadingContent
                    : t("login")}
                </button>
                <Link to={"/forgot-password"} className="login-link">
                  {t("forgotten_password")}
                </Link>
              </form>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="login-signin full-height">
              <div className="overlay-login">
                <div className="login-right-content">
                  <Link className="login-logo" to={"/"}>
                    {t("site_name")}
                  </Link>
                  <div className="login-details">
                    <h2 className="login-title white mb-2">
                      {t("site_para1")}
                    </h2>
                    <p className="login-desc mt-3 white tex-justify">
                      {t("site_para2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(Login));
