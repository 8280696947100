import React, { Component } from "react";

class PersonalStorage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <section
          className="banner-img-container banner-bg"
          style={{ backgroundImage: `url(../assets/img/luggage4.jpg)` }}
        >
          <div className="overlay-bg"></div>
          <div>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-details">
                  <h2 className="banner-title white mb-2">
                    Personal Storage Facility Near You
                  </h2>
                  <p className="banner-desc white">
                    Get instant access to reliable, on demand personal storage
                    space at your convenience.
                  </p>
                  <a href="single-list.html" className="btn btn-purple mt-4">
                    Get Estimate Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding" id="storage">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">We Make Storage Easy</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5 justify-content-md-center">
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-truck font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We come to you and do heavy lifting
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-warehouse font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We secure it in our storage facility
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-history font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We bring it back whenever you want
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/personal.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Household Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Are you moving as part of
                      your work?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Do you have more
                      belongings than space?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Are Items used
                      occasionally taking your living space?
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    If you're looking for self storage space in bangalore for
                    your luggage before settling in your new home, we offer
                    luggage storage that is convenient, cheap and secure.
                    Luggage storage available for short term and long term
                    period.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6 resp-order-changes">
                <h3 className="title_main">Luggage Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Are you relocating on
                      work?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Did you recently move into
                      Bangalore?
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    If you're looking for self storage space in bangalore for
                    your luggage before settling in your new home, we offer
                    luggage storage that is convenient, cheap and secure.
                    Luggage storage available for short term and long term
                    period.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/luggage.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/self-storage1.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Self Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Do you have Ancestral
                      gifts, Antique, special utensils?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Do you need storage for
                      Paintings, Musical instruments?
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Do you need space to store
                      Sewing machine, old equipments?
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Store your belongings which needs special care in our
                    specialized self storage units that ensures dry, clean,
                    ventilated and humid free environment. We care for the
                    sentimental attachment along with the monetary value.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color3">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="title_main white">
                  Yes, I need your help with storage
                </h3>
                <p className="desc white">
                  Contact us by phone or email to find out what we offer.
                </p>
              </div>
              <div className="col-md-4 text-right align-center mt-4">
                <a href="contact-us.html" className="btn btn-black">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default PersonalStorage;
