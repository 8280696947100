import React, { Component } from "react";
import { GoogleApiWrapper, InfoWindow, Marker, Map } from "google-maps-react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Helper from "../Helper/Helper";

const mapStyles = {
  width: "100%",
  height: "100%"
};

export class SingleSpaceMap extends Helper {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    content: {},
    loading: true
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      loading: false
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        loading: true
      });
    }
  };

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };
    const price = "234";
    const rate = "as";
    const { loading } = this.state;
    const { data } = this.props;

    return (
      <Map
        google={this.props.google}
        zoom={12}
        style={mapStyles}
        initialCenter={{
          lat: 12.844,
          lng: 77.6739
        }}
      >
        <Marker
          name={data.space_name}
          title={data.space_name}
          label={{
            text: data.per_day_formatted,
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff"
          }}
          icon={{
            url: window.location.origin + "/assets/mapicons/black-map.svg"
          }}
          position={{
            lat: data.latitude,
            lng: data.longitude
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyARW_YBJ-OU_RfSlMLlvLBHJaG-W_EQv4I"
})(SingleSpaceMap);
