import React, { Component } from "react";
import { GoogleApiWrapper, InfoWindow, Marker, Map } from "google-maps-react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Helper from "./Helper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const mapStyles = {
  width: "100%",
  height: "100%",
};

export class MapContainer extends Helper {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    content: {},
    loading: true,
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      loading: false,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        loading: true,
      });
    }
  };

  centerMoved = (mapProps, map) => {
    console.log("Map moved lat", map.center.lat());
    console.log("Map moved lng", map.center.lng());
    this.props.onMapPanned(map.center.lat(), map.center.lng());
  };

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    const data = "";
    const price = "234";
    const rate = "as";
    const { loading } = this.state;

    return (
      <Map
        google={this.props.google}
        zoom={12}
        style={mapStyles}
        onDragend={this.centerMoved}
        initialCenter={{
          lat:
            this.props.data && this.props.data.length > 0
              ? this.props.data[0].latitude
              : this.props.lastLat,
          lng:
            this.props.data && this.props.data.length > 0
              ? this.props.data[0].longitude
              : this.props.lastLng,
        }}
      >
        {this.props.data && this.props.data.length > 0
          ? this.props.data.map((content) => (
              <Marker
                onClick={this.onMarkerClick}
                name={content.space_name}
                title={content.space_name}
                content={content}
                label={{
                  text: content.per_day_formatted,
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#fff",
                }}
                icon={{
                  url:
                    window.location.origin + "/assets/mapicons/black-map.svg",
                }}
                position={{
                  lat: content.latitude,
                  lng: content.longitude,
                }}
              />
            ))
          : ""}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          {loading ? (
            ""
          ) : (
            <div className="col-md-12 margin-bottom">
              <div className="single-list-card">
                <div>
                  <Carousel
                    showThumbs={false}
                    infiniteLoop={true}
                    showStatus={false}
                  >
                    {this.state.activeMarker.content.gallery.length > 0
                      ? this.state.activeMarker.content.gallery.map(
                          (picture) => (
                            <div className="">
                              <img
                                src={picture.picture}
                                className="d-block w-100 carousel-photo"
                                alt="..."
                              />
                            </div>
                          )
                        )
                      : ""}
                  </Carousel>
                </div>
              </div>

              <a
                href={`/single-space/${this.state.activeMarker.content.space_id}`}
              >
                <div className="single-list-content mt-2">
                  <p className="green-text m-0 pb-2">
                    {this.state.activeMarker.content.space_location}
                  </p>
                  <h4 className="title m-0 pb-2">
                    {this.state.activeMarker.content.space_name}
                  </h4>
                  <p className="desc">
                    <span>Space Type</span>
                    <strong>
                      <span className="float-right">
                        {this.state.activeMarker.content.space_type}
                      </span>
                    </strong>
                  </p>
                  <p className="desc pb-2 no-margin">
                    {this.state.activeMarker.content.per_day_symbol}{" "}
                    <strong>
                      <span className="float-right">
                        {this.state.activeMarker.content.per_day_formatted}
                      </span>
                    </strong>
                  </p>
                </div>
              </a>
            </div>
          )}
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyARW_YBJ-OU_RfSlMLlvLBHJaG-W_EQv4I",
})(MapContainer);
