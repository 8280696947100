import React, { Component } from "react";

import { Link } from "react-router-dom";

class HomeHeader extends Component {
  state = {};
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container no-padding">
            <Link className="navbar-brand" to={"/"}>
              RentStorage
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-icon"></span>
              <span className="navbar-icon"></span>
              <span className="navbar-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle dropbtn" href="#">
                    Services
                  </a>
                  <div className="dropdown-content">
                    <Link to={"/personal-storage"}>Personal Storage</Link>
                    <Link to={"/business-storage"}>Business Storage</Link>
                    <Link to={"/vehicle-storage"}>Vehicle Storage</Link>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="single-list.html">
                    Location
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact-us.html">
                    Contact Us
                  </a>
                </li> */}
                {localStorage.getItem("userLoginStatus") != undefined ? (
                  <React.Fragment>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle dropbtn" href="#">
                        Bookings
                      </a>
                      <div className="dropdown-content">
                        <Link to={"/upcoming-bookings"}>Upcoming Booking</Link>
                        <Link to={"/booking-history"}>Booking History</Link>
                        {/* <Link to={"/booking-transaction"}>
                          Booking Transaction
                        </Link> */}
                      </div>
                    </li>
                    <li class="nav-item dropdown user-profile">
                      <a
                        class="dropdown-toggle user-link"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={localStorage.getItem("user_picture")} />
                        <span class="user-nav pl-2">
                          {localStorage.getItem("username")}
                        </span>
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link className="dropdown-item" to={"/profile"}>
                          Profile
                        </Link>
                        <Link className="dropdown-item" to={"/user-payment"}>
                          Payments
                        </Link>
                        <Link className="dropdown-item" to={"/change-password"}>
                          Change Password
                        </Link>
                        <Link className="dropdown-item" to={"/delete-account"}>
                          Delete Account
                        </Link>
                        <Link className="dropdown-item" to={"/logout"}>
                          Logout
                        </Link>
                      </div>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/login"}>
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/signup"}>
                        Sign Up
                      </Link>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default HomeHeader;
