import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import UserReviewModel from "./userReviewModel";
import SingleSpaceMap from "../Listing/SingleSpaceMap";
import Helper from "../Helper/Helper";

class SingleBookingHistory extends Helper {
  state = {
    booking_details: null,
    loading: true,
    checkinBtnStatus: 0,
    checkoutBtnStatus: 0,
    cancelBtnStatus: 0,
    reviewBtnStatus: 0,
    messageBtnStatus: 0,
    CheckinApiCalled: false,
    CheckoutApiCalled: false,
    reviewApiCalled: false,
    cancelApiCalled: false
  };
  componentDidMount() {
    this.getSingleHistoryDetailsApiCall();
  }

  getSingleHistoryDetailsApiCall = () => {
    api
      .postMethod("bookings_view", { booking_id: this.props.match.params.id })
      .then(response => {
        if (response.data.success) {
          this.setState({
            booking_details: response.data.data,
            loading: false
          });
        }
      });
  };
  bookingCheckin = event => {
    event.preventDefault();
    api
      .postMethod("bookings_checkin", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingCheckout = event => {
    event.preventDefault();
    api
      .postMethod("bookings_checkout", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  bookingCancel = event => {
    event.preventDefault();
    api
      .postMethod("bookings_cancel", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  bookingReview = event => {
    event.preventDefault();
    api
      .postMethod("bookings_rating_report", {
        booking_id: this.props.match.params.id
      })
      .then(response => {
        if (response.data.success) {
          this.getSingleHistoryDetailsApiCall();
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  render() {
    const { booking_details, loading } = this.state;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <h2 className="title_main">Single Booking View</h2>
              <i className="border-title"></i>
            </div>
          </div>
          {loading ? (
              "Loading..."
            ) : (
          <div className="row">
            
            <div className="col-xl-7 col-lg-7 col-md-12 margin-bottom">
              <div className="row">
                <div className="col-md-12 single-storage">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        <i className="fas fa-map-marker-alt mr-2 color-1"></i>
                        Storage Location
                      </a>
                    </li>
                    <li className="nav-item ml-2">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        <i className="fas fa-camera mr-2"></i>Photo Gallery
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div
                        className="map single-map-list mt-4"
                        style={{ width: "100%", height: "500px" }}
                      >
                        {loading ? (
                          "Loading..."
                        ) : (
                          <SingleSpaceMap data={booking_details} />
                        )}
                      </div>
                      <div className="gallery-content pt-4">
                        <h3 className="title">Description:</h3>
                        <p className="desc">
                          {booking_details.booking_description ? booking_details.booking_description : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div
                        id="carouselExampleFade"
                        className="carousel slide carousel-fade mt-4"
                        data-ride="carousel"
                      >
                        <div className="carousel-inner">
                          <Carousel
                            showThumbs={false}
                            infiniteLoop={true}
                            showStatus={false}
                          >
                            {loading
                              ? "Loading..."
                              : booking_details.gallery.length > 0
                              ? booking_details.gallery.map(image => (
                                  <div className="">
                                    <img
                                      src={image.picture}
                                      className="d-block w-100"
                                      alt="..."
                                    />
                                  </div>
                                ))
                              : ""}
                          </Carousel>
                        </div>

                        <div className="gallery-content pt-4">
                          <h3 className="title">
                            Description:
                          </h3>
                          <p className="desc">
                            {booking_details.booking_description ? booking_details.booking_description:  "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="contact">
                  <div className="row">
                    <div className="col-md-12 user-booking-history">
                      <div className="user-img">
                        <img
                          src={booking_details.picture}
                          className="object-fit-unset"
                        />
                      </div>
                      <div className="booking-details">
                        <h3 className="title">
                          {booking_details.provider_details.provider_name}
                        </h3>
                        <p className="desc">
                          {booking_details.provider_details.email}
                        </p>
                      </div>
                      <div className="mt-4">
                        <div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <h5 className="desc">Payment Id</h5>
                            </div>
                            <div className="col-md-7 text-right">
                              <p className="desc">
                                <strong>
                                  {booking_details.pricing_details.payment_id ? booking_details.pricing_details.payment_id : 'N/A'}
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-8">
                              <h5 className="desc">Payment Mode</h5>
                            </div>
                            <div className="col-md-4 text-right">
                              <p className="desc">
                                <strong>Card</strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">
                              <h5 className="desc">
                                Duration
                              </h5>
                              <p className="mt-2 location-desc">
                                {booking_details.location}
                              </p>
                            </div>
                            <div className="col-md-4 text-right">
                              <p className="desc">
                                <strong>
                                  {booking_details.price_type_text}
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row booking-history-summary mt-2">
                            <div className="col-md-6">
                              <label>
                                <i class="far fa-calendar-alt"></i>{" "}
                                <span className="checkin-date">
                                  {booking_details.checkin}
                                </span>
                              </label>
                            </div>
                            <div className="col-md-6 resp-text-right">
                              <label>
                                <i class="fas fa-chevron-right"></i>{" "}
                                <span className="checkout-date">
                                  {booking_details.checkout}
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="border-light mt-1"></div>
                          <div className="row mt-3">
                            <div className="col-md-8">
                              <h5 className="desc">Sub Total</h5>
                            </div>
                            <div className="col-md-4 text-right">
                              <p className="desc">
                                <strong>
                                  {
                                    booking_details.pricing_details
                                      .sub_total ? booking_details.pricing_details
                                      .sub_total_formatted : booking_details.sub_total_formatted
                                  }
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-8">
                              <h5 className="desc">Tax Price</h5>
                            </div>
                            <div className="col-md-4 text-right">
                              <p className="desc">
                                <strong>
                                  {
                                    booking_details.pricing_details
                                      .tax_price ? booking_details.pricing_details
                                      .tax_price_formatted : booking_details.tax_price_formatted
                                  }
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-8">
                              <h5 className="desc">Grand Total</h5>
                            </div>
                            <div className="col-md-4 text-right">
                              <p className="desc">
                                <strong>
                                  {
                                    booking_details.pricing_details
                                      .paid_amount ? booking_details.pricing_details
                                      .paid_amount_formatted : booking_details.total_formatted
                                  }
                                </strong>
                              </p>
                              <p className="sub-desc mt-2">
                                <strong> NOTE:</strong>{" "}
                                {booking_details.status_text}
                              </p>
                            </div>
                          </div>
                          <div className="border-light mt-3"></div>
                          <div className="row mt-4">
                            <div className="col-md-8">
                              <h4 className="payable-amount">Payable Amount</h4>
                            </div>
                            <div className="col-md-4 align-text-right">
                              <h4 className="payable-amount">
                                <strong>
                                  {
                                    booking_details.pricing_details
                                      .paid_amount ? booking_details.pricing_details
                                      .paid_amount_formatted : booking_details.total_formatted
                                  }
                                </strong>
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          {booking_details.buttons.cancel_btn_status == 1 ? (
                            <a
                              href="#"
                              className="btn btn-primary btn-lg btn-block"
                              onClick={event => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to cancel?"
                                  )
                                )
                                  this.bookingCancel(event);
                              }}
                            >
                              Cancel
                            </a>
                          ) : (
                            ""
                          )}
                          {booking_details.buttons.checkin_btn_status == 1 ? (
                            <a
                              href="#"
                              className="btn btn-primary btn-lg btn-block"
                              onClick={event => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to checkin?"
                                  )
                                )
                                  this.bookingCheckin(event);
                              }}
                            >
                              Check In
                            </a>
                          ) : (
                            ""
                          )}
                          {booking_details.buttons.checkout_btn_status == 1 ? (
                            <a
                              href="#"
                              className="btn btn-primary btn-lg btn-block"
                              onClick={event => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to checkout?"
                                  )
                                )
                                  this.bookingCheckout(event);
                              }}
                            >
                              Check Out
                            </a>
                          ) : (
                            ""
                          )}
                          {booking_details.buttons.review_btn_status == 1 ? (
                            <a
                              className="btn btn-primary btn-lg btn-block"
                              href="#"
                              data-toggle="modal"
                              data-target="#userReviewModel"
                            >
                              Review
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                        <UserReviewModel booking_id={this.props.match.params.id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 ">
                    {booking_details.reviews.ratings
                      ? 
                        <div className=" mb-4 mt-2">
                          <div className="gallery-content pt-4">
                            <h3 className="title">Review:</h3>
                            <p className="desc">
                              <div className="rating mb-2">
                                <span className="rating-star">
                                  {this.starRatingHost(
                                    booking_details.reviews.ratings,
                                    20
                                  )}
                                </span>
                              </div>
                            </p>
                            <p className="desc">{booking_details.reviews.review}</p>
                          </div>
                        </div>
                        
                      : ""}
              </div> 
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withToastManager(SingleBookingHistory);
