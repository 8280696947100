import React, { Component } from "react";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";

class StaticPage extends Component {
  state = {
    loading: true,
    data: null,
  };

  componentDidMount() {
    api
      .postMethod("static_pages_web", {
        unique_id: this.props.match.params.id,
      })
      .then((response) => {
        this.setState({
          loading: false,
          data: response.data.data,
        });
      });
  }

  render() {
    return (
      <section className="sm-padding bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main no-margin">
                {this.state.data ? this.state.data.title : "-"}
              </h2>
              <i className="border-title mb-5"></i>
            </div>
          </div>
          <div className="user-details">
            <div className="row">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.data ? this.state.data.description : "-",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(StaticPage);
