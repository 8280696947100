import React, { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import Helper from "../Helper/Helper";
import { Link } from "react-router-dom";

class PaymentPage extends Helper {
  state = {
    loadingCardList: true,
    cardList: null,
    data: {},
    buttonDisable: false,
    buttonLoadingText: null
  };

  componentDidMount() {
    console.log("Props", this.props.location.state.basicDetails);
    this.getCardList();
  }

  payNow = event => {
    event.preventDefault();
    const { basicDetails, pricingCalculatorInput } = this.props.location.state;
    const data = { ...this.state.data };
    data["space_id"] = basicDetails.space_id;
    data["checkin"] = pricingCalculatorInput.checkin;
    data["no_of_units"] = pricingCalculatorInput.no_of_units;
    data["payment_mode"] = "card";
    data["price_type"] = pricingCalculatorInput.price_type;
    data["total_days"] = pricingCalculatorInput.total_days;
    this.setState({ data });
    this.setState({
      buttonDisable: true,
      buttonLoadingText: "Loading... Please wait."
    });
    setTimeout(() => {
      this.paynowAPI();
    }, 1000);
  };

  paynowAPI = () => {
    api.postMethod("spaces_bookings_create", this.state.data).then(response => {
      if (response.data.success) {
        this.setState({
          buttonDisable: false,
          buttonLoadingText: null
        });
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.props.history.push(`/single-booking-history/${response.data.data.booking_id}`);
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };
  render() {
    const {
      basicDetails,
      pricing,
      pricingCalculatorInput,
      buttonDisable,
      buttonLoadingText
    } = this.props.location.state;
    const { loadingCardList, cardList } = this.state;
    return (
      <section className="sm-padding bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-7 res-mrg-btm">
              <h4 className="mb-4 title">Payment Method</h4>
              <div className="row payment-method">
                {loadingCardList
                  ? "Loading..."
                  : cardList.cards.length > 0
                  ? cardList.cards.map(cards => (
                      <div className="col-md-12" key={cards.user_card_id}>
                        <div className="radiobtn mb-4 bg-shadow-1">
                          <input
                            type="radio"
                            name="plan"
                            id="inline-radio-1"
                            className="form-check-input"
                            value="reach"
                            checked="checked"
                          />
                          <label
                            type="radio"
                            for="inline-radio-1"
                            className="form-check-label card-label"
                          >
                            <h3 className="payment-head-tit">
                              {cards.card_name}
                            </h3>
                            <p className="desc">
                              XXXX XXXX XXXX {cards.last_four}
                            </p>
                          </label>
                        </div>
                      </div>
                    ))
                  : <div className="col-md-12 mt-3">
                      <p className="desc">
                        Please Add card and try again <br></br>
                      </p>
                      <Link
                        to={{
                          pathname: "/user-payment",
                        }}
                        className="btn btn-purple btn-lg "
                      >
                        Add card
                      </Link>
                </div>}
              </div>
            </div>
            <div className="col-md-5">
              <h4 className="mb-4 title">Order Summary</h4>
              <div className="booking-total bg-shadow-1">
                <div className="summary-booking">
                  <h5>Invoice</h5>
                </div>
                <div className="package-price">
                  <div className="row">
                    <div className="col-md-8">
                      <h5>{basicDetails.space_name}</h5>
                      <p className="mt-2">{basicDetails.space_location}</p>
                    </div>
                    <div className="col-md-4 text-right">
                      <p></p>
                    </div>
                  </div>
                  <div className="border-light mt-3"></div>
                  {pricingCalculatorInput.price_type == "per_day" ? (
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>Number of Days</h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <p>
                          <strong>{pricingCalculatorInput.total_days}</strong>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>Number of Month</h5>
                      </div>
                      <div className="col-md-4 text-right">
                        <p>
                          <strong>1</strong>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>Number of Sqft</h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricingCalculatorInput.total_sqft}</strong>
                      </p>
                    </div>
                  </div>

                  <div className="border-light mt-3"></div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>Price</h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.sub_total_formatted}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>Tax Price</h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.tax_price}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="border-light mt-3"></div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <h5>Grand Total</h5>
                    </div>
                    <div className="col-md-4 text-right">
                      <p>
                        <strong>{pricing.total_formatted}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="suggestion mt-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="1"
                      placeholder="Any suggestions? We will pass on it..."
                      name="description"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  <div className="border-light mt-3"></div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <h4 className="payable-amount">Payable Amount</h4>
                    </div>
                    <div className="col-md-4 text-right">
                      <h4 className="payable-amount">
                        {pricing.total_formatted}
                      </h4>
                    </div>
                  </div>
                  <a
                    className="btn btn-outline-purple btn-block mt-5"
                    onClick={this.payNow}
                    disable={buttonDisable}
                  >
                    {buttonLoadingText != null ? buttonLoadingText : "Pay Now"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(PaymentPage);
