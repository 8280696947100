import React, { Component } from "react";

class VehicleStorage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <section
          className="banner-img-container banner-bg"
          style={{ backgroundImage: `url(../assets/img/vehicle-1.jpg)` }}
        >
          <div className="overlay-bg"></div>
          <div>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="banner-details">
                  <h2 className="banner-title white mb-2">
                    On Demand Vehicle Storage Facility
                  </h2>
                  <p className="banner-desc white">
                    When you're ready to pack, we've got everything you need
                    including logistics to move your belongings from your end to
                    our warehouse.
                  </p>
                  <a href="single-list.html" className="btn btn-purple mt-4">
                    Get Estimate Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding" id="storage">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="title_main">We Make Storage Easy</h3>
                <i className="border-title"></i>
              </div>
            </div>
            <div className="row mt-5 justify-content-md-center">
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-truck font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We come to you and do heavy lifting
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 resp-margin-btm-md text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-warehouse font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We secure it in our storage facility
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 text-center">
                <div className="feature-box">
                  <div className="icon mb-4">
                    <i className="fa fa-history font-animate"></i>
                  </div>
                  <div className="details">
                    <h4 className="title">
                      We bring it back whenever you want
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/vehicle-2.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Car Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Flexible options to store
                      your vehicle
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Doorstep vehicle pickup
                      and delivery
                    </li>
                    <li>
                      <i className="fas fa-check"></i>PRegularly maintenance and
                      care of your vehicle
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Customers can store their car at our facility for short term
                    or long term duration depending upon their needs. Customers
                    can come and park their car by themselves or we also provide
                    doorstep facility along with delivery. We follow the regular
                    documentation before storing your car at our storage
                    facility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-6 resp-order-changes">
                <h3 className="title_main">Boat Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>Most boats will fit in a
                      10×15 storage unit.
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Larger boats may need a
                      10×20 or 10×30 storage unit.
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Note that storage units
                      have heights ranging from eight to ten feet.
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    Because marina storage can basically cost you your whole
                    life’s savings, boats can be stored like RV’s, outdoors or
                    indoors. What a surprise. For indoor boat storage you’re
                    basically looking at large warehouses that can protect them
                    from weather conditions. Outdoor vehicle storage can provide
                    both covered or uncovered parking on storage facilities
                    property. Again, the price of storing your water-based
                    vehicle depends on the type of vehicle storage you get.
                    Uncovered is always the cheapest.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/boat-storage.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color6">
          <div className="container">
            <div className="row">
              <div className="col-md-6 pr-5 resp-padding-right-zero">
                <div className="single-img">
                  <img src="../assets/img/bike-storage.jpg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="title_main">Bike Storage Facility</h3>
                <div className="primary-list mt-20">
                  <ul className="p-0">
                    <li>
                      <i className="fas fa-check"></i>We are a "one stop shop"
                      for bike storage products.
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Uncovered storage place,
                      Covered storage place and Indoor unit{" "}
                    </li>
                    <li>
                      <i className="fas fa-check"></i>High Quality Bike Storage,
                      Great Value for money Bike Storage
                    </li>
                  </ul>
                  <p className="desc mb-0">
                    For a change of pace, let’s talk about RV’s for a bit, and
                    how to best store these gentle giants. Now these things can
                    be a bit of an eyesore when parked in front of your suburban
                    home, and unless you want your neighbors getting anxious and
                    upset at you, you’ll need to find another place to park it.
                    So where does it go? Just like a car it can be stored in any
                    storage facility that has the capacity to hold one..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm-padding bg-color3">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="title_main white">
                  Yes, I need your help with storage
                </h3>
                <p className="desc white">
                  Contact us by phone or email to find out what we offer.
                </p>
              </div>
              <div className="col-md-4 align-center text-right mt-4">
                <a href="contact-us.html" className="btn btn-black">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default VehicleStorage;
