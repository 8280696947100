import React, { Component } from "react";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class EditProfile extends Component {
  state = {
    data: {},
    loading: true,
    profileError: null,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false,
    imagePreviewUrl: null
  };

  componentDidMount() {
    if (this.props.location.state != null) {
      this.setState({ loading: false });
      this.setState({ data: this.props.location.state });
    } else {
      this.getUserDetails();
    }
  }

  getUserDetails() {
    api.postMethod("profile").then(response => {
      if (response.data.success === true) {
        this.setState({ data: response.data.data, loading: false });
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          this.setState({ profileError: "Please login to continue" });
        } else {
          this.setState({ profileError: response.data.error });
        }
      }
    });
  }

  handleImageChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });

    let userDetails = { ...this.state.data };
    const data = {
      name: userDetails.name,
      description: userDetails.description,
      email: userDetails.email,
      mobile: userDetails.mobile,
      timezone: const_time_zone
    };
    api.postMethod("update_profile", data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };
  render() {
    const { loading, imagePreviewUrl } = this.state;
    let data = {};
    if (this.props.location.state != null) {
      data = this.props.location.state;
    } else {
      data = this.state.data;
    }
    return (
      <section className="sm-padding-div bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-box row xs-padding-div">
                <div className="col-md-5">
                  <div>
                    <img 
                    className="profile-dp"
                      src={
                        loading
                          ? "Loading"
                          : imagePreviewUrl
                          ? imagePreviewUrl
                          : data.picture
                      }
                    />
                    <form className="profile-form mt-5">
                      <div className="form-group">
                        <label htmlFor="change-profile-photo">
                          {t("choose_photo")}
                        </label>
                        <input
                          type="file"
                          name="picture"
                          className="form-control-file btn btn-purple"
                          id="change-profile-photo"
                          onChange={this.handleImageChange}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-7 padding-left">
                  <div className="profile-name border-bottom">
                    <p className="user-name title_main">
                      {t("welcom_to_edit_profile")}
                    </p>
                  </div>
                  <div className="personal-information">
                    <form className="profile-form">
                      <div className="form-group">
                        <label>{t("name")}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("name_required")}
                          name="name"
                          value={loading ? "Loading" : data.name}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("email")}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("email_required")}
                          name="email"
                          value={loading ? "Loading.." : data.email}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("address")}</label>
                        <input
                          type="phone"
                          className="form-control"
                          placeholder={t("address_required")}
                          name="address"
                          value={loading ? "Loading.." : data.address}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>{t("mobile")}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("mobile_required")}
                          name="mobile"
                          value={loading ? "Loading..." : data.mobile}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <button
                            disabled={this.state.buttonDisable}
                            className="btn btn-purple"
                          >
                            {this.state.loadingContent != null
                              ? this.state.loadingContent
                              : t("save")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(EditProfile));
