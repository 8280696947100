import React, { Component } from "react";
import api from "../../Environment";
import { Link } from "react-router-dom";

class BookingHistory extends Component {
  state = {
    histories: null,
    loadingHistory: true
  };
  componentDidMount() {
    api.postMethod("bookings_history").then(response => {
      if (response.data.success === true) {
        this.setState({ histories: response.data.data, loadingHistory: false });
      }
    });
  }

  render() {
    const { histories, loadingHistory } = this.state;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main">Booking History</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {loadingHistory
                ? "Loading..."
                : histories.length > 0
                ? histories.map(history => (
                    <div className="user-booking-history">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="user-img">
                            <img src={history.space_picture} alt="user" />
                          </div>
                          <div className="booking-details">
                            <h3 className="title">{history.name}</h3>
                            <p className="desc">{history.space_location}</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <p className="desc">
                            Checkin<strong> : {history.checkin}</strong>
                          </p>
                          <p className="desc">
                            Checkout <strong> : {history.checkout}</strong>
                          </p>
                          <p className="desc">
                            Total Amount
                            <strong> : {history.total_formatted}</strong>
                          </p>
                        </div>
                        <div className="col-md-4 text-center res-txt-left mt-2">
                          <Link
                            className="btn btn-purple"
                            to={`/single-booking-history/${history.booking_id}`}
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                : "No data found"}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 mx-auto">
              <a href="#" className="btn btn-purple btn-block">
                Load More
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BookingHistory;
