import React, { Component } from "react";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

class DeleteAccount extends Helper {
  state = {
    data: {},
    loadingContent: null,
    buttonDisable: false,
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api.postMethod("delete_account", this.state.data).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");

        this.setState({ loadingContent: null, buttonDisable: false });
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLoginStatus");
        localStorage.removeItem("user_picture");
        localStorage.removeItem("username");
        window.location = "/";
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };
  render() {
    return (
      <section className="sm-padding bg-color4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <img
                  src="../assets/img/delete-account.svg"
                  alt=""
                  className="success"
                />
              </div>
              <form className="delete-form" onSubmit={this.handleDelete}>
                <div className="row mb-4">
                  <div className="col-md-6 mx-auto">
                    <div className="xs-padding pb-0">
                      <h5 className="title mb-3 text-center">
                        {t("sorry_to_see_you_go")}
                      </h5>

                      <div className="form-group">
                        <label>{t("old_password")}</label>
                        <input
                          type="password"
                          className="form-control mb-1"
                          placeholder={t("old_password_placeholder")}
                          value={this.state.data.password}
                          onChange={this.handleChange}
                          name="password"
                        />
                        <p className="desc text-center mt-2">
                          {t("account_delete_para")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-purple btn-lg"
                    disabled={this.state.buttonDisable}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : t("delete_account")}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(DeleteAccount));
