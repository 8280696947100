import React, { Component } from "react";
import Helper from "../Helper/Helper";

class SingleSpaceListingReview extends Helper {
  state = {
    reviews: null,
    loadingReviews: true
  };
  componentDidMount() {
    this.getReviews(this.props.data.space_id);
  }
  render() {
    const { reviews, loadingReviews } = this.state;
    return (
      <div>
        {loadingReviews
          ? "Loading..."
          : reviews.length > 0
          ? 
          <section className="sm-padding bg-color6">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="title"> Review on {this.props.data.space_name}</h2>
                  <div className="row ">
                    {loadingReviews
                      ? "Loading..."
                      : reviews.length > 0
                      ? reviews.map(review => (
                          <div className="col-md-12 mb-4 mt-2">
                            <div className="card p-3 no-border bg-shadow">
                              <div className="row no-gutters">
                                <div className="col-md-2">
                                  <div className="user-img">
                                    <img src={review.user_picture} />
                                  </div>
                                </div>
                                <div className="col-md-10 review">
                                  <div className="card-body p-0 ml-3">
                                    <div className="card-body no-padding">
                                      <h5 className="review-user-name mb-2">
                                        {review.user_name}
                                      </h5>
                                      <div className="rating mb-2">
                                      <span className="rating-star">
                                        {this.starRatingHost(
                                          review.ratings,
                                          20
                                        )}
                                      </span><br></br>
                                        <span className="review-date">
                                          {review.updated}
                                        </span>
                                      </div>

                                      <p className="desc">{review.review}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : "No data found"}
                  </div>
                </div>
              </div>
            </div>
          </section>
        
        : ""}
      </div>
    );
  }
}

export default SingleSpaceListingReview;
