import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";

class SingleSpaceContent extends Helper {
  state = {};
  render() {
    return (
      <div className="row md-padding">
        {this.props.data.map(space => (
          <div className="col-md-4 mb-4 pr-2 margin-bottom">
            <div className="single-list-card">
              <div>
                <Carousel
                  showThumbs={false}
                  infiniteLoop={true}
                  showStatus={false}
                >
                  {space.gallery.length > 0
                    ? space.gallery.map(picture => (
                        <div className="card_img">
                          <img
                            src={picture.picture}
                            className="d-block w-100 carousel-photo"
                            alt="..."
                          />
                        </div>
                      ))
                    : (
                      <div className="card_img">
                        <img
                          src={space.space_picture}
                          className="d-block w-100 carousel-photo"
                          alt="..."
                        />
                      </div>
                    )}
                </Carousel>
              </div>
              
            <Link to={`/single-space/${space.space_id}`}>
              <div className="single-list-content mt-2">
                <p className="green-text m-0 pb-2">{space.space_location}</p>
                <h4 className="title m-0 pb-2">{space.space_name}</h4>
                <p className="desc">
                  <span>Space Type</span>
                  <strong>
                    <span className="float-right">{space.space_type}</span>
                  </strong>
                </p>
                <p className="desc pb-2 no-margin">
                  Per Day
                  <strong>
                    <span className="float-right">
                      {space.per_day_formatted}
                    </span>
                  </strong>
                </p>
                <div className="rating">
                <span className="rating-star">
                    {this.starRatingHost(
                      space.avg_space_reviews,
                      13
                    )}
                  </span>
                </div>
              </div>
            </Link>
            </div>

          </div>
        ))}
      </div>
    );
  }
}

export default SingleSpaceContent;
