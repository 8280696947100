import React, { Component } from "react";

class SimilarListing extends Component {
  state = {
    serviceLocation: null,
    loadingServiceLocation: true
  };
  render() {
    
    const { serviceLocation, loadingServiceLocation } = this.props.data;
    
    return (
      <div>
        {loadingServiceLocation
          ? "Loading..."
          : serviceLocation 
          ? 
      <section className="sm-padding similar-location bg-color6">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main">Similar Locations</h2>
              <i className="border-title mb-5"></i>
              <div className="row">
              {serviceLocation.length > 0
                      ? serviceLocation.map(serviceLocation => (
                <div className="col-md-4 margin-bottom">
                  <div className="card similar-list-card">
                    <img
                      src={serviceLocation.picture}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="right-content">
                        <h4 className="title">{serviceLocation.name}</h4>
                        <p className="desc">
                        {serviceLocation.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                ))
                      : "No data found"}
                
              </div>
            </div>
          </div>
        </div>
      </section>
      : ""}
      </div>
    );
  }
}

export default SimilarListing;
