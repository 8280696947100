import React, { Component } from "react";

class SingleSpaceAmenities extends Component {
  state = {};
  render() {
    const { amenties } = this.props;
    return (
      <section className="sm-padding">
        <div className="container">
          <div className="col-md-12 mb-5">
            <h3 className="title_main">All Inclusive Amenities</h3>
            <i className="border-title"></i>
          </div>
          <div className="row">
            {amenties.data.map(amentity => (
              <div className="col-md-3 mb-5" key={amenties.space_amenities_id}>
                <div className="amenities text-center">
                  <div className="amenities-img">
                    <img src={amentity.picture} />
                  </div>
                  <h4 className="amenities-title">{amentity.name}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default SingleSpaceAmenities;
