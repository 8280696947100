import React, { Component } from "react";

class BookingTransaction extends Component {
  state = {};
  render() {
    return (
      <section className="sm-padding booking-transaction">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title_main">Booking Transaction</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table bg-shadow-1 table-responsive-sm">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Details</th>
                    <th scope="col">Your Commission</th>
                    <th scope="col">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10 Sep 2019</td>
                    <td>Card</td>
                    <td>Booking Payment</td>
                    <td>$ 140.40</td>
                    <td>$ 240.40</td>
                  </tr>
                  <tr>
                    <td>11 Sep 2019</td>
                    <td>Card</td>
                    <td>Booking Payment</td>
                    <td>$ 150.50</td>
                    <td>$ 250.00</td>
                  </tr>
                  <tr>
                    <td>12 Sep 2019</td>
                    <td>Card</td>
                    <td>Booking Payment</td>
                    <td>$ 160.60</td>
                    <td>$ 260.60</td>
                  </tr>
                  <tr>
                    <td>13 Sep 2019</td>
                    <td>Card</td>
                    <td>Booking Payment</td>
                    <td>$ 230.60</td>
                    <td>$ 421.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 mx-auto">
              <a href="#" className="btn btn-purple btn-block">
                Load More
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BookingTransaction;
