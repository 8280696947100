import React, { Component } from "react";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { Link } from "react-router-dom";
import Helper from "../Helper/Helper";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class Signup extends Helper {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual",
      agree: 0,
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { path } = this.props.location;

    if (this.state.data.agree != 1) {
      this.setState({
        loadingContent: null,
        buttonDisable: false,
      });
      ToastDemo(this.props.toastManager, "Please click the agree box", "error");
    } else {
      this.setState({
        loadingContent: "Loading... Please wait..",
        buttonDisable: true,
      });
      api
        .postMethod("register", this.state.data)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userLoginStatus", true);

            window.location = path ? path.from.pathname : "/home";
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
            // window.locatiom = path
            //   ? this.props.history.push(path.from.pathname)
            //   : this.props.history.push("/home");
            // this.props.history.push("/home");
          } else {
            ToastDemo(this.props.toastManager, response.data.error, "error");
            // $("#signup").modal("hide");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch(function (error) {});
    }
  };

  render() {
    const { data } = this.state;
    return (
      <section className="sign-up bg-color4">
        <div className="row">
          <div className="col-md-6 p-0">
            <div className=" signup-left-content">
              <h3 className="title_main text-center">{t("signup")}</h3>
              <p className="text-center desc">
                {t("or")}
                <a href="/login" className="login-link ml-1">
                  {" "}
                  {t("login")}
                </a>
              </p>
              <form className="signup-form" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label className="col-form-label">{t("name")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("name_placeholder")}
                    name="name"
                    onChange={this.handleChange}
                    value={data.name}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">{t("email_address")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("email_address_placeholder")}
                    onChange={this.handleChange}
                    name="email"
                    value={data.email}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">{t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder={t("password_placeholder")}
                    name="password"
                    onChange={this.handleChange}
                    value={data.password}
                  />
                </div>
                <div className="input-group">
                  <label className="text-none">
                    <input
                      type="checkbox"
                      name="agree"
                      value="1"
                      onChange={this.handleChange}
                      className="signup_agree"
                    />{" "}
                    I agree to the{" "}
                    {/* {configuration.get("configData.site_name")} */}
                    <Link to={`/page/privacy`} target="_blank">
                      {" "}
                      {t("privacy_policy")}{" "}
                    </Link>
                    and
                    <Link to={`/page/terms`} target="_blank">
                      {" "}
                      {t("terms_of_service")}{" "}
                    </Link>
                  </label>
                </div>
                <button
                  disabled={this.state.buttonDisable}
                  className="btn btn-purple btn-block mt-4"
                >
                  {this.state.loadingContent != null
                    ? this.state.loadingContent
                    : t("signup")}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="signup full-height">
            <div className="overlay-login">
              <div className="signup-right-content ">
                <Link className="login-logo" to={"/"}>
                  {t("site_name")}
                </Link>
                <div className="signup-details">
                  <h2 className="login-title white mb-2">
                    {t("site_para1")}
                  </h2>
                  <p className="login-desc white">
                    {t("site_para2")}
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withToastManager(translate(Signup));
